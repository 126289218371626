import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Button } from 'react-bootstrap';
import QuoteSite from '../components/QuoteSite';
import { useLocation } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import ConfigHandler from '../config';
import { RestService } from '../utils';
import NewQuoteConfirmModal from '../components/NewQuoteConfirmModal';
import { Trans, useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { BrowserRouter as Router, Redirect } from 'react-router-dom';
import { setSidebarStatus } from '../store/actions/sidebar';
import { AuthHandler } from '../utils/AuthHandler';
import { useDispatch } from 'react-redux';
import { Icon } from '@mui/material';
import DeleteQuoteModal from '../components/DeleteQuoteModal';

import '../scss/views/NewQuote.scss';

const EditQuote = () => {
  const [selectedPartner, setSelectedPartner] = useState(null);
  const [validated, setValidated] = useState(false);
  //const dispatch = useDispatch();
  const [countOfSite, setCountOfSite] = useState(0);
  const [siteForms, setSiteForms] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [finalObj, setFinalObj] = useState({});
  const [totalsObj, setTotalObj] = useState({ totMRC: 0, totNRC: 0, tot: 0 });
  const [showTotal, setShowTotal] = useState(false);
  const [redirectTo, SetRedirectTo] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [isDraft, setIsDraft] = useState();
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const [viewMode, setViewMode] = useState(false);
  const [formData, setFormData] = useState();
  const [input, setInput] = useState('');
  const [input2, setInput2] = useState('');
  const [submittedInput, setSubmittedInput] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  //useEffect(() => {
  //  addSite();
  //}, []);

  // useEffect(() => {
  //   setValidated(false);
  //   setCountOfSite(1);
  //   setSiteForms([0]);
  //   setSelectedClient(null);
  //   calculateTotal();
  // }, [selectedPartner])

  useEffect(() => {
    if (location.state != undefined || '') {
      setIsEditing(true);
      setSelectedClient(location.state.data.client);
      setSelectedPartner(location.state.data.partner)
      setCountOfSite(location.state.data.quoteDetails.length);
      let SitesArray = [];

      for (var i = 0; i < location.state.data.quoteDetails.length; i++) {
        SitesArray.push(i);
      }

      if (location.state.data.status === 1) {
        setViewMode(true);
      }

      setSiteForms(SitesArray);
    }
  }, [location.state]);

  //Client Async Select
  const handleClient = (value) => {
    setSelectedClient(value);
  };

  const loadClient = (inputValue, callback) => {
    if (inputValue.length >= 3) {
      return RestService.get({
        url: `${ConfigHandler.getConfig().api.baseUrl}${ConfigHandler.getConfig().api.path.getActiveCompany}?q=${inputValue}`
      }).then((response) => response.json()
        .then((data) => ({ data }))
        .then((res) => {
          callback(res.data);
          return res.data;
        })
      );
    }
  };

  /**
   * It takes an inputValue and a callback function as parameters, and returns a promise that resolves
   * to the data returned by the API call.
   * 
   * The callback function is called with the data returned by the API call.
   * 
   * The promise resolves to the data returned by the API call.
   * 
   * The promise is returned by the function.
   * @param inputValue - The value of the input field
   * @param callback - function(data: Array)
   * @returns a promise.
   */
  const loadPartners = (inputValue, callback) => {
    return RestService.get({ url: `${ConfigHandler.getConfig().api.baseUrl}${ConfigHandler.getConfig().api.path.getPartners}?q=${inputValue}&offset=0&limit=50`, })
      .then((response) => response.json()
        .then((data) => ({ data }))
        .then((res) => {
          callback(res.data.data);
          return res.data.data;
        }));
  };

  // const loadClientForPartner = (inputValue, callback) => {
  //   return RestService.get({ url: `${ConfigHandler.getConfig().api.baseUrl}${ConfigHandler.getConfig().api.path.getClientOfPartner}?q=${inputValue}&partnerId=${selectedPartner.id}`, showLoader: false })
  //     .then((response) => response.json()
  //       .then((data) => ({ data }))
  //       .then((res) => {
  //         callback(res.data);
  //         return res.data;
  //       }));
  // };

  const handlePartner = (value) => {
    setSelectedPartner(value);
    setSelectedClient(null);
  };

  //Custom Styles for React-Select
  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      border: '1px solid  rgb(168, 168, 168)',
      alignText: 'left !important'
    }),
    control: (base, state) => ({
      ...base,
      boxShadow: 'none',
      border: state.isFocused && 'none',
      '&:hover': {
        //borderColor:"rgb(168, 168, 168)",
      },
      '&:focus-within': {
        border: '1px solid #ed3a7f',
        boxShadow: '0 0 0 3px rgba(255, 211, 198, 0.38)'
      },
      '&:invalid': {
        border: '1px solid green'
      }
    })
  };

  /**
   * It loops through the localStorage and adds up the MRC and NRC values of each item.
   * @returns {
   *   "totMRC": 0,
   *   "totNRC": 0,
   *   "tot": 0
   * }
   */
  const calculateTotal = () => {
    var MRCtot = 0;
    var NRCtot = 0;
    var tot = 0;

    if (localStorage.getItem(`SITE-SELECTED-0`) === undefined) {
      setShowTotal(false);
      return;
    }

    for (var i = 0; i < countOfSite; i++) {
      if (localStorage.getItem(`SITE-SELECTED-${i}`) != undefined) {
        var selected = JSON.parse(localStorage.getItem(`SITE-SELECTED-${i}`));
        var selPrice = selected.comunicaInfo.listino[0];
        if (!(
              (selPrice.mrc === undefined || selPrice.mrc === null || selPrice.mrc == 0) 
            &&
              (selPrice.nrc === undefined || selPrice.nrc === null || selPrice.nrc == 0)
        )) {
          MRCtot = parseFloat(MRCtot) + parseFloat(selPrice.mrc);
          NRCtot = parseFloat(NRCtot) + (selPrice.nrc ? parseFloat(selPrice.nrc) : 0);
          tot = !selPrice.durata ? undefined : parseFloat(tot) + parseFloat(selPrice.nrc) + (selPrice.durata * (selPrice.mrc ? parseFloat(selPrice.mrc) : 0));
        }
      } else {
        continue;
      }
    }
    setShowTotal(NRCtot>0 || MRCtot>0);

    setTotalObj({ totMRC: MRCtot.toFixed(2), totNRC: NRCtot.toFixed(2), tot: !tot ? '' : tot.toFixed(2) });
  };

  useEffect(() => {
    return () => {
      let i = countOfSite;
      //for (var i = 0; i <= countOfSite; i++) {
        localStorage.removeItem(`SITE-SELECTED-${i}`);
        localStorage.removeItem(`SITE-${i}`);
        //console.log("removeItem", i );
      //}
    };
  }, [countOfSite]);

  /**
   * If the form is valid, set the form data and show the confirmation modal.
   * @param event - the event that is triggered when the form is submitted
   * @returns The form data is being returned.
   */
  const ConfirmForm = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    if (form.checkValidity() === false || selectedClient === null) {
      event.stopPropagation();
      toast.warning(t('NOTIFICATIONS.NotificationMissingFields'));
      return;
    }

    setFormData(form);
    setShowConfirmationModal(true);
  };

  /**
   * It takes the data from the form and sends it to the server.
   * @param data - {
   */
  const HandleSubmit = (data) => {
    var error = false;
    var sitesData = [];

    //Check if all Sites are Validated
    for (var i = 0; i < countOfSite; i++) {
      var selected = JSON.parse(localStorage.getItem(`SITE-SELECTED-${i}`));

      if (selected == '' || selected == undefined || selected == null) {
        toast.error(`${t('NEWQUOTE.NOTIFICATIONS.NotificationMissingSite')} ${i + 1}`);
        error = true;
      } else {
        sitesData.push(selected);
        error = false;
      }
    }

    if (isDraft != undefined) {
      var finalObject = {
        id: location.state.data.id,
        clientId: selectedClient.id,
        referenceName: data.CustomerName.value,
        referencePhone: data.CustomerPhone.value,
        note: data.CustomerNote.value,
        detailList: [...sitesData],
        status: isDraft
      };

      if (error === false) {
        setValidated(true);
        setFinalObj(finalObject);
      }

      if (error === false) {
        RestService.put(
          (AuthHandler.getUserData().role == 'ADMIN' && (selectedPartner?.id != null || selectedPartner?.id != undefined))
            ? {
              url: `${ConfigHandler.getConfig().api.baseUrl}${ConfigHandler.getConfig().api.path.newQuote}?partnerId=${selectedPartner.id}`,
              body: finalObject
            }
            : {
              url: `${ConfigHandler.getConfig().api.baseUrl}${ConfigHandler.getConfig().api.path.newQuote}`,
              body: finalObject
            }
        ).then((res) => {
          if (res.status > 199 && res.status < 300) {
            isDraft === 0 ? toast.success(t('NEWQUOTE.NOTIFICATIONS.NotificationQuoteDraftSaved')) : toast.success(t('NEWQUOTE.NOTIFICATIONS.NotificationQuoteSubmitted'));
            SetRedirectTo(true);
          } else {
            res.json().then((statusText) => {
              toast.error(
                <>
                  {t('NOTIFICATIONS.NotificationSomethingWrong')} <br />
                  <small>
                    <i>{statusText.error}</i>
                  </small>
                </>
              );
            });
            setIsDraft(null);
          }
        });
      }
    }
  };

  /**
   * It adds a new site to the list of sites.
   */
  const addSite = () => {
    setCountOfSite(countOfSite + 1);
    let OriginalSites = [];
    OriginalSites = [...siteForms];
    OriginalSites.push(countOfSite);
    setSiteForms(OriginalSites);
  };

  /**
   * If the count of sites is greater than 1, then remove the site from the array and local storage.
   * @param toremoveVal - the value of the site to be removed
   */
  let removeSite = (toremoveVal) => {
    if (countOfSite > 1) {
      setCountOfSite(countOfSite - 1);
      let OriginalArray = [];
      OriginalArray = [...siteForms];
      OriginalArray.splice(OriginalArray.indexOf(toremoveVal), 1);  //OriginalArray.splice(toremoveVal, 1);
      setSiteForms(OriginalArray);
      localStorage.removeItem(`SITE-SELECTED-${toremoveVal}`);
      localStorage.removeItem(`SITE-${toremoveVal}`);
      //console.log("removeItem", toremoveVal );
    } else {
      toast.warning(t('NEWQUOTE.NOTIFICATIONS.NotificationNoSite'));
    }
  };

  const generateSitesForms = () => {
    return siteForms.map((index) => (
      <QuoteSite
        keyID={index}
        removeSite={removeSite}
        addSite={addSite}
        calculateTotal={calculateTotal}
        key={`QuoteSite-${index}`}
        view={viewMode}
        //partner
        editingMode={isEditing}
        datas={location.state.data.quoteDetails[index]}
        quoteID={location.state.data.id}
      />
    ));
  };

  return (
    <>
      {redirectTo === true ? <Redirect to="/requests/drafts" /> : null}
      { showDeleteModal && <DeleteQuoteModal setShowDeleteModal={setShowDeleteModal} data={location.state.data} setToRedirect={SetRedirectTo} /> }
      {showConfirmationModal === true ? (
        <NewQuoteConfirmModal
          setShowConfirmationModal={setShowConfirmationModal}
          setIsDraft={setIsDraft}
          HandleSubmit={HandleSubmit}
          formData={formData}
        />
      ) : null}
      <Form className="mx-5" onSubmit={ConfirmForm} id="newQuote">
        <div className="form">
          <div className="form__container">

            <Row className='mb-4'>
              <Col md={11}>
                <p className="h3 form__NewQuote__MainTitle">
                  {viewMode === false && isEditing === false && t('NEWQUOTE.PageTitle')}
                  {viewMode === false && isEditing && t('NEWQUOTE.PageTitleEditing')}
                  {viewMode && isEditing && t('NEWQUOTE.PageTitleViewing')}
                </p>
              </Col>
              <Col md={1} className='text-center'>
                <div className='fadedText' onClick={() => setShowDeleteModal(true)}>
                  <Icon style={{ fontSize: '2rem' }}>
                    delete
                  </Icon>
                  <p className="small">
                    Delete
                  </p>
                </div>
              </Col>
            </Row>
            {
              AuthHandler.getUserData().role == 'ADMIN'
              &&
              (
                <Row className="mb-4 mt-4">
                  <Form.Group as={Col} md={12} controlId="validationPartner">
                    <AsyncSelect
                      cacheOptions
                      defaultOptions
                      isClearable={true}
                      value={selectedPartner}
                      noOptionsMessage={() => t('OTHERS.NoDataMessage')}
                      loadOptions={loadPartners}
                      onChange={handlePartner}
                      getOptionLabel={(e) => e.name}
                      getOptionValue={(e) => e.name}
                      placeholder={t('PRICEBOOK.COMPANY.SelectPartner')}
                      isDisabled={true}
                    />
                  </Form.Group>
                </Row>
              )
            }










            








            {(
                <Row className="mb-4 mt-4">
                  <Form.Group as={Col} md={12} controlId="validationClientName">
                    <AsyncSelect
                      className="AdminNewUSer__AsyncSelect_Company"
                      cacheOptions
                      noOptionsMessage={() => t('OTHERS.Min3CharLabel')}
                      value={selectedClient}
                      loadOptions={loadClient}
                      onChange={handleClient}
                      getOptionLabel={(e) => e.companyName}
                      getOptionValue={(e) => e.id}
                      styles={customStyles}
                      placeholder={t('FORMS.SelectCustomerOption')}
                      isDisabled={true}
                    />
                  </Form.Group>
                </Row>
            )}
            {
              (selectedClient != null) && (
                <>
                  <Row className='mb-3'>
                    <Form.Group as={Col} md={6} controlId="CustomerName">
                      <Form.Label className="float-start NewClient_labels">{t('FORMS.ReferenceNameLabel')} <span className="NewClient_star_symbol">*</span></Form.Label>
                      <Form.Control type="text" defaultValue={location.state.data.referenceName} required placeholder={t('FORMS.ReferenceNamePlaceholder')} />
                    </Form.Group>
                    <Form.Group as={Col} md={6} controlId="CustomerPhone">
                      <Form.Label className="float-start NewClient_labels">{t('FORMS.ReferencePhoneLabel')} <span className="NewClient_star_symbol">*</span></Form.Label>
                      <Form.Control type="tel" defaultValue={location.state.data.referencePhone} pattern="^(\+|[0-9])[0-9]{6,32}$" placeholder={t('FORMS.ReferencePhonePlaceholder')} required />
                    </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group as={Col} md={12} controlId="CustomerNote">
                      <Form.Label className="float-start NewClient_labels">{t('FORMS.CommentsLabel')}</Form.Label>
                      <Form.Control as="textarea" rows={3} maxLength="255" defaultValue={location.state.data.note} />
                    </Form.Group>
                  </Row>
                </>
              )}
          </div>
        </div>
      </Form>
      <Row>
        <Col md={12}>{generateSitesForms()}</Col>
      </Row>

      {showTotal && localStorage.getItem('SHOW_PRICEBOOK_PRICE') == 'true' ? (
        <Row className="ol-details-footer item-center">
          <Col md={{ span: 4, offset: 8 }} className="footer-total">
            <Row>
              <Col className="text-right">
                <p className="fadedText">{t('NEWQUOTE.TotalNrcLabel')} :</p>
              </Col>
              <Col className="text-right">
                <p>{(totalsObj.totNRC).toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })}</p>
              </Col>
            </Row>
            <Row>
              <Col className="text-right">
                <p className="fadedText">{t('NEWQUOTE.TotalMrcLabel')} :</p>
              </Col>
              <Col className="text-right">
                <p>{(totalsObj.totMRC).toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })}</p>
              </Col>
            </Row>
          </Col>
        </Row>
      ) : null}
      {viewMode === false ? (
        <Row>
          <Col md={{ span: 4, offset: 8 }} className="item-right">
            <Button type="submit" form="newQuote">
              {t('BUTTONS.SAVE')}
            </Button>
          </Col>
        </Row>
      ) : null}
    </>
  );
};

export default EditQuote;

/*
            <Row>
              <Col className="text-right">
                <p className="fadedText">{t('NEWQUOTE.TotalLabel')} :</p>
              </Col>
              <Col className="text-right">
                <p>{(totalsObj.tot).toLocaleString('it-IT', {style: 'currency', currency: 'EUR'})}</p>
              </Col>
            </Row>
*/