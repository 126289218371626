import Login from './containers/Login';
import Dashboard from './views/Dashboard';
import Registration from './components/Registration';
import ForgotPassword from './views/ForgotPassword';
import ChangePasswordView from './views/ChangePasswordView';
import Admin from './components/Admin';
import AdminNewUser from './views/AdminNewUser';
import CompanyProfile from './views/CompanyProfile';
import NewClient from './views/NewClient';
import NewQuote from './views/NewQuote';
import NewFeasibility from './views/NewFeasibility';
import ModalPasswordChange from './components/ModalPasswordChange';
import MyProfile from './views/MyProfile';
import QuoteList from './views/DraftQuote';
import FeasibilityList from './views/FeasibilityList';
import TeamMembers from './views/TeamMembers';
import ManageCompany from './views/manageCompany';
import NotFound from './components/404';
import ClientList from './views/ClientList';
import ConfirmQuote from './views/ConfirmQuote';
import EditQuote from './views/EditQuote';
import LoginResponsive from './containers/LoginResponsive';
import ConfirmedQuote from './views/ConfirmedQuote';
import ManagePricebooks from './views/ManagePricebooks';
import UploadPricebook from './components/UploadPricebook';
import FirstLogin from './components/FirstLogin';
import PricebookTable from './components/PricebookTable';
import AddPricebookForm from './components/AddPricebookForm';
import PricebookHistoryTable from './components/PricebookHistoryTable';
import PortalConfiguration from './views/PortalConfiguration';
import HelpPricebook from './views/HelpPages/HelpPricebook';
import HelpPage from './views/HelpPages/HelpPage';
import HelpQuote from './views/HelpPages/HelpQuote';
import HelpAdmin from './views/HelpPages/HelpAdmin';

const Routes = [
  {
    path: '/login/responsive',
    component: LoginResponsive,
  },
  {
    path: '/requests/viewquote',
    component: ConfirmQuote,
    breadcrumbs: {
      mainSlug: 'requests',
      mainTitle: {
        en: 'Requests',
        it: 'Preventivi'
      },
      subSlug: 'view_quote',
      subTitle: {
        en: 'View Quote',
        it: 'Visualizza Preventivo'
      }
    }
  },
  {
    path: '/requests/newquote',
    component: NewQuote,
    breadcrumbs: {
      mainSlug: 'requests',
      mainTitle: {
        en: 'Requests',
        it: 'Preventivi'
      },
      subSlug: 'new_quote',
      subTitle: {
        en: 'New Quote',
        it: 'Nuovo Preventivo'
      }
    },
    roles: ['BROKER', 'USER', 'ADMIN'],
  },
  {
    path: '/requests/editquote',
    component: EditQuote,
    breadcrumbs: {
      mainSlug: 'requests',
      mainTitle: {
        en: 'Requests',
        it: 'Preventivi'
      },
      subSlug: 'edit_quote',
      subTitle: {
        en: 'Edit Quote',
        it: 'Modifica Preventivo'
      }
    },
  },
  {
    path: '/requests/drafts',
    component: QuoteList,
    breadcrumbs: {
      mainSlug: 'requests',
      mainTitle: {
        en: 'Requests',
        it: 'Preventivi'
      },
      subSlug: 'draft-list',
      subTitle: {
        en: 'Drafts',
        it: 'Bozze'
      }
    },
  },
  {
    path: '/requests/orders',
    component: ConfirmedQuote,
    breadcrumbs: {
      mainSlug: 'requests',
      mainTitle: {
        en: 'Requests',
        it: 'Preventivi'
      },
      subSlug: 'order-list',
      subTitle: {
        en: 'Orders List',
        it: 'Ordini',
      }
    },
  },
  {
    path: '/requests/newfeasibility',
    component: NewFeasibility,
    breadcrumbs: {
      mainSlug: 'requests',
      mainTitle: {
        en: 'Requests',
        it: 'Richieste'
      },
      subSlug: 'feasibility-new',
      subTitle: {
        en: 'New Feasibility',
        it: 'Nuova Fattibilità'
      }
    },
    roles: ['BROKER']
  },
  {
    path: '/requests/feasibility',
    component: FeasibilityList,
    breadcrumbs: {
      mainSlug: 'requests',
      mainTitle: {
        en: 'Requests',
        it: 'Richieste'
      },
      subSlug: 'feasibility-list',
      subTitle: {
        en: 'Feasibility',
        it: 'Fattibilità'
      }
    },
    roles: ['BROKER'],
  },
  {
    path: '/forgot/password',
    component: ForgotPassword,
    breadcrumbs: {
      mainSlug: 'user',
      mainTitle: {
        en: 'User',
        it: 'Utenti'
      },
      subSlug: 'forgot_password',
      subTitle: {
        en: 'Forgot Password',
        it: 'Password Dimenticata'
      }
    },
  },
  {
    path: '/admin/managecompany',
    component: ManageCompany,
    roles: ['Admin', 'Broker'],
    breadcrumbs: {
      mainSlug: 'admin',
      mainTitle: {
        en: 'Admin',
        it: 'Amministrazione'
      },
      subSlug: 'manage_company',
      subTitle: {
        en: 'Manage Company',
        it: 'Gestione Aziende'
      }
    },
  },
  {
    path: '/admin/teammembers',
    component: TeamMembers,
    roles: ['Admin', 'Broker'],
    breadcrumbs: {
      mainSlug: 'admin',
      mainTitle: {
        en: 'Admin',
        it: 'Amministrazione',
      },
      subSlug: 'manage_team_members',
      subTitle: {
        en: 'Manage Team Members',
        it: 'Gestione Membri del Team',
      }
    },
  },
  {
    path: '/admin/managepricebooks',
    component: ManagePricebooks,
    roles: ['Admin'],
    breadcrumbs: {
      mainSlug: 'admin',
      mainTitle: {
        en: 'Admin',
        it: 'Amministrazione',
      },
      subSlug: 'manage_pricebook',
      subTitle: {
        en: 'Manage Pricebook',
        it: 'Gestione Pricebook',
      }
    },
  },
  {
    path: '/admin/pricebookupload',
    component: UploadPricebook,
    roles: ['Admin'],
    breadcrumbs: {
      mainSlug: 'admin',
      mainTitle: {
        en: 'Admin',
        it: 'Amministrazione',
      },
      subSlug: 'manage_pricebook_upload',
      subTitle: {
        en: 'Upload Pricebook',
        it: 'Carica Pricebook',
      }
    },
  },
  {
    path: '/admin/pricebooktable',
    component: PricebookTable,
    roles: ['Admin'],
    breadcrumbs: {
      mainSlug: 'admin',
      mainTitle: {
        en: 'Admin',
        it: 'Amministrazione',
      },
      subSlug: 'manage_pricebook_table',
      subTitle: {
        en: 'Pricebook Table',
        it: 'Tabbella Pricebook',
      }
    },
  },
  {
    path: '/admin/pricebookhistory',
    component: PricebookHistoryTable,
    roles: ['Admin'],
    breadcrumbs: {
      mainSlug: 'admin',
      mainTitle: {
        en: 'Admin',
        it: 'Amministrazione',
      },
      subSlug: 'pricebook_history',
      subTitle: {
        en: 'Pricebook History',
        it: 'Storico Pricebook',
      }
    },
  },
  {
    path: '/admin/addpricebook',
    component: AddPricebookForm,
    roles: ['Admin'],
    breadcrumbs: {
      mainSlug: 'admin',
      mainTitle: {
        en: 'Admin',
        it: 'Amministrazione',
      },
      subSlug: 'manage_pricebook_add',
      subTitle: {
        en: 'Add Pricebook',
        it: 'Aggiungi Pricebook',
      }
    },
  },
  {
    path: '/user/profile',
    component: MyProfile,
    breadcrumbs: {
      mainSlug: 'user',
      mainTitle: {
        en: 'User',
        it: 'Utenti'
      },
      subSlug: 'profile',
      subTitle: {
        en: 'Profile',
        it: 'Profilo'
      }
    },
  },
  {
    path: '/modalpasswordchange',
    component: ModalPasswordChange,
    breadcrumbs: {
      mainSlug: null,
      mainTitle: {
        en: null,
        it: null
      },
      subSlug: null,
      subTitle: {
        en: null,
        it: null
      },
    },
  },
  {
    path: '/clients/newclient',
    component: NewClient,
    breadcrumbs: {
      mainSlug: 'clients',
      mainTitle: {
        en: 'Customers',
        it: 'Clienti'
      },
      subSlug: 'new_client',
      subTitle: {
        en: 'New Customers',
        it: 'Nuovo Cliente'
      }
    },
  },
  {
    path: '/clients/clientlist',
    component: ClientList,
    breadcrumbs: {
      mainSlug: 'clients',
      mainTitle: {
        en: 'Customers',
        it: 'Clienti'
      },
      subSlug: 'client_list',
      subTitle: {
        en: 'Customers List',
        it: 'Lista Clienti'
      },
    },
  },
  {
    path: '/login',
    component: Login,
    breadcrumbs: {
      mainSlug: null,
      mainTitle: {
        en: null,
        it: null
      },
      subSlug: null,
      subTitle: {
        en: null,
        it: null
      },
    },
  },
  {
    path: '/admin/newcompany',
    component: CompanyProfile,
    breadcrumbs: {
      mainSlug: 'admin',
      mainTitle: {
        en: 'Admin',
        it: 'Amministrazione'
      },
      subSlug: 'new_company',
      subTitle: {
        en: 'New Company',
        it: 'Nuova Azienda'
      }
    },
  },
  {
    path: '/admin/newuser',
    component: AdminNewUser,
    roles: ['Admin', 'Broker'],
    breadcrumbs: {
      mainSlug: 'admin',
      mainTitle: {
        en: 'Admin',
        it: 'Amministrazione'
      },
      subSlug: 'new_user',
      subTitle: {
        en: 'New User',
        it: 'Nuovo Utente'
      }
    },
  },
  {
    path: '/admin/configuration',
    component: PortalConfiguration,
    roles: ['Admin', 'Broker'],
    breadcrumbs: {
      mainSlug: 'admin',
      mainTitle: {
        en: 'Admin',
        it: 'Amministrazione'
      },
      subSlug: 'portal_configuration',
      subTitle: {
        en: 'Portal Configuration',
        it: 'Configurazione Portale'
      }
    },
  },
  {
    path: '/help/pricebook',
    component: HelpPricebook,
    roles: ['Admin'],
    breadcrumbs: {
      mainSlug: 'help_pricebook',
      mainTitle: {
        en: 'Help',
        it: 'Aiuto'
      },
      subSlug: 'pricebook',
      subTitle: {
        en: 'Pricebook',
        it: 'Pricebook'
      }
    },
  },
  {
    path: '/help/admin',
    component: HelpPricebook,
    roles: ['Admin'],
    breadcrumbs: {
      mainSlug: 'help_admin',
      mainTitle: {
        en: 'Help',
        it: 'Aiuto'
      },
      subSlug: 'admin',
      subTitle: {
        en: 'Admin',
        it: 'Amministrazione'
      }
    },
  },
  {
    path: '/help/quote',
    component: HelpAdmin,
    roles: ['Admin'],
    breadcrumbs: {
      mainSlug: 'help',
      mainTitle: {
        en: 'Help',
        it: 'Aiuto'
      },
      subSlug: 'help_quote',
      subTitle: {
        en: "Quote",
        it: "Preventivi"
      }
    },
  },
  {
    path: '/help',
    component: HelpPage,
    roles: ['Admin'],
    breadcrumbs: {
      mainSlug: 'help',
      mainTitle: {
        en: 'Help',
        it: 'Aiuto'
      },
      subSlug: null,
      subTitle: {
        en: null,
        it: null
      }
    },
  },
  {
    path: '/changepasswordview',
    component: ChangePasswordView,
    breadcrumbs: {
      mainSlug: null,
      mainTitle: {
        en: null,
        it: null
      },
      subSlug: null,
      subTitle: {
        en: null,
        it: null
      },
    },
  },
  {
    path: '/registration',
    component: Registration,
    breadcrumbs: {
      mainSlug: null,
      mainTitle: {
        en: null,
        it: null
      },
      subSlug: null,
      subTitle: {
        en: null,
        it: null
      },
    },
  },
  {
    path: '/admin',
    component: Admin,
    roles: ['ADMIN', 'BROKER'],
    breadcrumbs: {
      mainSlug: 'admin',
      mainTitle: {
        en: 'Admin',
        it: 'Amministrazione'
      },
      subSlug: null,
      subTitle: {
        en: null,
        it: null
      },
    },
  },
  {
    path: '/dashboard',
    component: Dashboard,
    roles: ['ADMIN', 'BROKER', 'USER'],
    breadcrumbs: {
      mainSlug: 'dashboard',
      mainTitle: {
        en: 'Dashboard',
        it: 'Dashboard'
      },
      subSlug: null,
      subTitle: {
        en: null,
        it: null
      },
    },
  },
  {
    path: '/',
    component: ConfirmedQuote,
    exact: true,
    breadcrumbs: {
      mainSlug: 'requests',
      mainTitle: {
        en: 'Requests',
        it: 'Preventivi'
      },
      subSlug: 'order-list',
      subTitle: {
        en: 'Orders List',
        it: 'Ordini',
      }
    },
  },
  {
    pathname: '*',
    component: NotFound,
    breadcrumbs: {
      mainSlug: 'not_found',
      mainTitle: {
        en: 'Page Not Found',
        it: 'Pagina Non Trovata'
      },
      subSlug: '404',
      subTitle: {
        en: '404',
        it: '404'
      }
    },
  },
  {
    pathname: '/firstlogin',
    component: FirstLogin,
    breadcrumbs: {
      mainSlug: 'first_login',
      mainTitle: {
        en: 'First Login',
        it: 'Primo Login'
      },
      subSlug: null,
      subTitle: {
        en: null,
        it: null
      },
    },
  },
];

export default Routes;
