import React, { useState, useEffect } from 'react';
import { Form, Row, Col/*, Button*/ } from 'react-bootstrap';
import FeasibilitySite from '../components/FeasibilitySite';
import ConfigHandler from '../config';
import { RestService } from '../utils';
import { /*Trans, */useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { /*BrowserRouter as Router, */Redirect } from 'react-router-dom';

import '../scss/views/NewQuote.scss';

const NewFeasibility = () => {
  const [readyToSend, SetReadyToSend] = useState(false);
  const [redirectTo, SetRedirectTo] = useState(false);
  const { t/*, i18n*/ } = useTranslation();
  const [showCoordinates, setShowCoordinates] = useState(false);

  const [formData, setFormData] = useState({
    latitude: '',
    longitude: '',
    referenceName: '',
    referencePhone: '',
    bandwidth: '',
    notes: '',
    city: '',
    street: '',
    streetNumber: '',
    buildingNumber: '',
    codiceIstat: '',
    codiceEgon: '',
    sitecomplete: ''
  });

  useEffect(() => {
    console.log("NewFeasibility 36", formData, showCoordinates);
    let complete = false;
    if ((!showCoordinates) && (formData["sitecomplete"] == "1")) complete = true;
    if ((showCoordinates) && (formData["bandwidth"]) && (formData["latitude"]) && (formData["longitude"])) complete = true;
    SetReadyToSend(complete);
  }, [formData, showCoordinates])
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  //Custom Styles for React-Select
  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      border: '1px solid  rgb(168, 168, 168)',
      alignText: 'left !important',
    }),
    control: (base, state) => ({
      ...base,
      boxShadow: 'none',
      border: state.isFocused && 'none',
      '&:focus-within': {
        border: '1px solid #ed3a7f',
        boxShadow: '0 0 0 3px rgba(255, 211, 198, 0.38)',
      },
      '&:invalid': {
        border: '1px solid green',
      },
    }),
  };

  /* 
  //preso dal FeasibilitySite
  const HandleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();

    if (form.checkValidity() === false || selectedCity === null || selectedStreet === null) {
      event.stopPropagation();
      setValidated(false);

      setData(null);
      return null;
    }

    setValidated(true);
    setTableParams({ istat: selectedCity.codiceIstat, egon: selectedStreet ? selectedStreet.codiceEgon : '', civico: selectedStreetNumber ? selectedStreetNumber.civico : '' });

    localStorage.setItem(`SITE-FEASIBILITY`, JSON.stringify({ istat: selectedCity.codiceIstat }));
  };
  */

  const inItaly = (lat, lng) => {
    const rectangles = [
      [[36.0, 6.0], [40.0, 10.0]],
      [[40.0, 10.0], [42.0, 12.0]],
      [[42.0, 10.0], [44.0, 12.5]],
      [[36.0, 10.0], [38.0, 12.0]],
      [[38.0, 12.0], [40.0, 14.0]],
      [[40.0, 12.0], [42.0, 14.5]],
      [[42.0, 12.5], [44.0, 15.0]],
      [[44.0, 12.0], [46.0, 15.5]],
      [[36.0, 15.0], [39.0, 18.0]],
      [[39.0, 15.0], [42.0, 18.0]],
      [[36.0, 18.0], [39.0, 20.0]],
      [[44.0, 15.5], [46.0, 18.0]],
      [[41.0, 6.0], [43.0, 8.0]],
      [[41.0, 8.0], [43.0, 10.0]],
      [[39.0, 8.0], [41.0, 10.0]],
      [[38.0, 15.0], [39.0, 16.0]],
      [[36.0, 12.0], [37.5, 13.5]],
      [[37.5, 12.0], [39.0, 13.5]],
      [[39.0, 13.5], [40.5, 15.0]],
      [[41.5, 14.0], [42.5, 15.0]],
      [[38.5, 15.5], [40.5, 18.0]],
      [[36.5, 15.5], [38.0, 18.0]],
      [[39.0, 14.0], [41.0, 15.5]],
      [[41.5, 10.0], [44.0, 12.5]], 
      [[43.0, 12.0], [44.0, 13.5]], 
      [[41.5, 11.0], [43.0, 13.0]], 
      [[42.5, 11.5], [43.5, 13.0]], 
      [[41.5, 13.0], [42.5, 14.5]], 
      [[41.5, 14.0], [42.5, 15.0]], 
      [[39.0, 14.0], [41.0, 15.5]],
      [[39.0, 15.0], [42.0, 18.0]],
      [[40.0, 15.0], [41.5, 17.0]],
      [[38.5, 15.5], [40.5, 18.0]],
      [[36.5, 12.5], [38.0, 15.5]],
      [[38.5, 8.0], [40.0, 10.0]],
      [[36.0, 10.0], [37.5, 12.0]],
      [[37.5, 10.0], [39.0, 12.0]],
      [[39.0, 12.0], [40.5, 14.0]],
      [[41.0, 14.0], [42.0, 15.0]],
      [[42.0, 15.0], [43.0, 16.0]],
      [[40.0, 10.0], [41.0, 11.0]],
      [[39.0, 10.0], [40.0, 11.0]],
      [[36.5, 6.0], [37.5, 7.0]],
      [[37.5, 6.0], [38.5, 7.0]],
      [[38.5, 6.0], [39.5, 7.0]],
      [[40.0, 12.0], [41.0, 13.0]], 
      [[41.0, 12.0], [42.0, 13.0]], 
      [[42.0, 12.0], [43.0, 13.0]], 
      [[43.0, 12.0], [44.0, 13.0]], 
      [[44.0, 12.0], [45.0, 13.0]], 
      [[45.0, 12.0], [46.0, 13.0]]  
    ];
    for (const rect of rectangles) if (lat >= rect[0][0] && lat <= rect[1][0] && lng >= rect[0][1] && lng <= rect[1][1]) return true;
    return false;
  }

  /**
   * It takes the data from the form and sends it to the server.
   * @param data - {
   */
  const HandleSubmit = (data) => {
    data.preventDefault();

    const form = data.currentTarget;
    if (form.checkValidity() === false) {
      data.stopPropagation();
      toast.warning(t('NOTIFICATIONS.NotificationMissingFields'));
      return;
    }

    if (showCoordinates) {
      const lat = parseFloat(formData.latitude);
      const lng = parseFloat(formData.longitude);
      if (!inItaly(lat, lng)) {
        toast.error(`${t('NEWFEASIBILITY.NOTIFICATIONS.CoordsNotInItaly')}`);
        return;
      }
    }

    if (!readyToSend) {
      toast.error(`${t('NEWFEASIBILITY.NOTIFICATIONS.NotificationMissingSite')}`);
    } else {
      const finalData = {
        ["referenceName"]: formData["referenceName"],
        ["referencePhone"]: formData["referencePhone"],
        ["bandwidth"]: formData["bandwidth"],
        ["note"]: formData["notes"],

        ["latitude"]: showCoordinates ? formData["latitude"] : "",
        ["longitude"]: showCoordinates ? formData["longitude"] : "",

        ["city"]: showCoordinates ? "" : formData["city"],
        ["street"]: showCoordinates ? "" : formData["street"],
        ["streetNumber"]: showCoordinates ? "" : formData["streetNumber"],
        ["buildingNumber"]: showCoordinates ? "" : formData["buildingNumber"],
        ["istatCode"]: showCoordinates ? "" : formData["codiceIstat"],
        ["egonCode"]: showCoordinates ? "" : formData["codiceEgon"],
      };

      console.log("POST url", `${ConfigHandler.getConfig().api.baseUrl}${ConfigHandler.getConfig().api.path.postFeasibility}`);
      console.log("payload", finalData);

      if (readyToSend) {
        RestService.post(
          {
            url: `${ConfigHandler.getConfig().api.baseUrl}${ConfigHandler.getConfig().api.path.postFeasibility}`,
            body: finalData
          }
        ).then((res) => {
          if (Math.floor(res.status / 100) === 2) {
            toast.success(t('NEWFEASIBILITY.NOTIFICATIONS.NotificationSubmitted'));
            SetRedirectTo(true);
          } else {
            res.json().then((statusText) => {
              toast.error(
                <>
                  {t('NOTIFICATIONS.NotificationSomethingWrong')} <br />
                  <small>
                    <i>{statusText.error}</i>
                  </small>
                </>,
              );
            });
          }
        });
      }
      
    }
    
  };

  return (
    <>
      {redirectTo === true ? <Redirect to="/requests/feasibility" /> : null}
      
      <Form className="mx-5" onSubmit={HandleSubmit} id="newFeasibility">
        <div className="form">
          <div className="form__container">
            <h3 className="fadedText mb-5">{t('NEWFEASIBILITY.PageTitle')}</h3>
            {
              showCoordinates ?
                (
                  <>
                    <Row className='my-1'>
                      <Form.Group as={Col}>
                        <Form.Check type="checkbox" label={t('FORMS.FeasibilityCoordinates')} checked={showCoordinates} onChange={() => setShowCoordinates(!showCoordinates)} />
                      </Form.Group>
                    </Row>
                    <Row className='mt-4 mb-2'>
                      <Form.Group as={Col} md={6} controlId="Latitude">
                        <Form.Label className="float-start NewClient_labels">{t('FORMS.LatitudeLabel')} <span className="NewClient_star_symbol">*</span></Form.Label>
                        <Form.Control value={formData.latitude} name="latitude" onChange={handleChange} type="number" step="any" placeholder={t('FORMS.LatitudePlaceholder')} min="-90" max="90" required />
                      </Form.Group>
                      <Form.Group as={Col} md={6} controlId="Longitude">
                        <Form.Label className="float-start NewClient_labels">{t('FORMS.LongitudeLabel')} <span className="NewClient_star_symbol">*</span></Form.Label>
                        <Form.Control value={formData.longitude} name="longitude" onChange={handleChange} type="number" step="any" placeholder={t('FORMS.LongitudePlaceholder')} min="-180" max="180" required />
                      </Form.Group>
                    </Row>
                  </>
                ) : (
                  <>
                    <Row className='mt-4 mb-2'>
                      <Col><FeasibilitySite formData={formData} setFormData={setFormData} /></Col>
                    </Row>
                    <Row className='my-1'>
                      <Form.Group as={Col}>
                        <Form.Check type="checkbox" label={t('FORMS.FeasibilityCoordinates')} checked={showCoordinates} onChange={() => setShowCoordinates(!showCoordinates)} />
                      </Form.Group>
                    </Row>
                  </>
                )
            }
            {
              (
                <>
                  <Row>
                    <Col><hr /></Col>
                  </Row>
                  <Row className='mb-3'>
                    <Form.Group as={Col} md={6} controlId="CustomerName">
                      <Form.Label className="float-start NewClient_labels">{t('FORMS.ReferenceNameLabel')}<span className="NewClient_star_symbol">*</span></Form.Label>
                      <Form.Control value={formData.referenceName} name="referenceName" onChange={handleChange} type="text" placeholder={t('FORMS.ReferenceNamePlaceholder')} required />
                    </Form.Group>
                    <Form.Group as={Col} md={6} controlId="CustomerPhone">
                      <Form.Label className="float-start NewClient_labels">{t('FORMS.ReferencePhoneLabel')} <span className="NewClient_star_symbol">*</span></Form.Label>
                      <Form.Control value={formData.referencePhone} name="referencePhone" onChange={handleChange} type="tel" pattern="^(\+|[0-9])[0-9]{6,32}$" placeholder={t('FORMS.ReferencePhonePlaceholder')} required />
                    </Form.Group>
                  </Row>
                  <Row className='mb-3'>
                    <Form.Group as={Col} md={6} controlId="Bandwidth">
                      <Form.Label className="float-start NewClient_labels">{t('FORMS.BandwidthLabel')}<span className="NewClient_star_symbol">*</span></Form.Label>
                      <Form.Select value={formData.bandwidth} name="bandwidth" onChange={handleChange} required>
                        <option value="">{t('FORMS.BandwidthPlaceholder')}</option>
                        <option value="100 Mbps">100 Mbps</option>
                        <option value="200 Mbps">200 Mbps</option>
                        <option value="300 Mbps">300 Mbps</option>
                        <option value="500 Mbps">500 Mbps</option>
                        <option value="1 Gbps">1 Gbps</option>
                        <option value="2 Gbps">2 Gbps</option>
                        <option value="10 Gbps">10 Gbps</option>
                        <option value="100 Gbps">100 Gbps</option>
                      </Form.Select>
                    </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group as={Col} md={12} controlId="CustomerNote">
                      <Form.Label className="float-start NewClient_labels">{t('FORMS.CommentsLabel')} </Form.Label>
                      <Form.Control value={formData.notes} name="notes" onChange={handleChange} as="textarea" rows={3} maxLength="255" />
                    </Form.Group>
                  </Row>
                </>
              )}
          </div>
        </div>
      </Form>
        <Row>
          <Col md={{ span: 4, offset: 8 }} className="item-right">
           <div className="m-3">
            <button disabled={!readyToSend} type="submit" form="newFeasibility" className="btn fadedButton">{t('BUTTONS.SUBMIT')}</button>
          </div>
        </Col>
      </Row>
    </>
  )
};

export default NewFeasibility;
