import React, { useState, Fragment } from 'react';
import '../scss/components/DataTables.scss';
import { Container, Row  } from 'react-bootstrap';
import DataTable from '../components/DataTable';
import { useSelector, useDispatch } from 'react-redux';
import { AuthHandler } from '../utils/AuthHandler';
import { RestService } from '../utils';
import ConfigHandler from '../config';
import Utils from '../utils/Utils';
import { useTranslation } from 'react-i18next';

function FeasyList() {
  let userRole;
  const { t } = useTranslation();

  if (AuthHandler.isAuth()) {
    userRole = AuthHandler.getUserData();
  }

  const state = useSelector((state) => state);

  //Needed Variables
  const [listData, setData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [sortDirection, setSortDir] = useState(false);
  const [sortVal, setSortVal] = useState('dateInserted:desc');
  const [numofData, setnumofData] = useState(0);
  const [sortIcon, setSortIcon] = useState('expairingDate');
  const [showCloneModal, setShowCloneModal] = useState(false);
  const [toCloneData, setToCloneData] = useState(null);
  let dataReturn = [];
  
  //Search Functionalities
  const [inputText, setInputText] = useState('');
  const dispatch = useDispatch();

  //Fetch new table data when needed
  const fetchData = React.useCallback(({ pageSize, pageIndex }) => {
    const startRow = pageSize * pageIndex;
    const endRow = startRow + pageSize;
    dataReturn = RestService.get({
      url: `${ConfigHandler.getConfig().api.baseUrl}${
        ConfigHandler.getConfig().api.path.getFeasibilityList
      }?q=${inputText}&offset=${startRow}&limit=${pageSize}&order=${sortVal}`
    }).then((response) =>
      response
        .json()
        .then((data) => ({
          data: data
        }))
        .then((res) => {
          dataReturn = res.data.data;
          if (dataReturn != undefined) {
            setData(dataReturn);
          } else if (dataReturn === undefined) {
            document.getElementsByClassName(`TH-${sortIcon}`)[0].classList.remove('sortedTabAsc', 'sortedTabDesc');
          }
          setnumofData(res.data.total);
          setPageCount(Math.ceil(res.data.total / pageSize));
        })
    );
    const userData = AuthHandler.getUserData();
  });

  //Get user search data
  let inputHandler = (e) => {
    var lowerCase = e.target.value.toLowerCase();
    Utils.handleInputSearch(lowerCase).then((text) => {
      setInputText(text);
    });
  };

  //Handle sorting
  const sortHandler = (val) => {
    document.getElementsByClassName(`TH-${val}`)[0].classList.remove('sortedTabAsc', 'sortedTabDesc');
    if (sortDirection) {
      setSortVal(`${val}:asc`);
      setSortDir(false);
      document.getElementsByClassName(`TH-${val}`)[0].classList.add('sortedTabAsc');
      setSortIcon(val);
    } else {
      setSortVal(`${val}:desc`);
      setSortDir(true);
      document.getElementsByClassName(`TH-${val}`)[0].classList.add('sortedTabDesc');
      setSortIcon(val);
    }
  };

  //Columns
  let columns = React.useMemo(
    () => [
      {
        customWidth: '100px',
        Header: '#',
        sortable: false,
        accessor: 'id',
        Cell: ({ row }) => {
          const { id, submittedDate } = row.original;
          const dt = submittedDate ? submittedDate.toString().replace(/-/g, '').slice(2, 8) : '';
          const dt_id = dt ? `${dt}.${id}` : "";
          return (
            <span>{dt_id}</span>
          );
        }
      },
      {
        customWidth: '90px',
        Header: t('FORMS.CreationDateLabel'),
        accessor: 'submittedDate',
        Cell: ({ value }) => {
          const dt = value ? (new Date(value)).toLocaleDateString() : '';
          return(
            <span>{dt}</span>
          )
        }
      },
      {
        customWidth: '130px',
        Header: t('FORMS.ReferenceNameLabel'),
        sortable: false,
        accessor: 'reference',
        Cell: ({ row }) => {
          const { referenceName, referencePhone } = row.original;
          return (
            <span>
              <strong>{referenceName}</strong><br/>
              <small>{referencePhone}</small>
            </span>
          );
        }
      },
      {
        customWidth: '100px',
        Header: t('FORMS.BandwidthLabel'),
        sortable: false,
        accessor: 'bandwidth'
      },
      {
        customWidth: '135px',
        Header: t('FORMS.CoordinatesLabel'),
        sortable: false,
        accessor: 'coordinates',
        Cell: ({ row }) => {
          const { latitude, longitude } = row.original;
          return (
            <span>
              <small>
                {latitude}<br/>
                {longitude}
              </small>
            </span>
          );
        }
      },
      {
        Header: t('FORMS.LocationLabel'),
        sortable: false,
        accessor: 'location',
        Cell: ({ row }) => {
          const { city, street, streetNumber, buildingNumber, istatCode, egonCode } = row.original;
          return city ? (
            <span>
              <small><i>({istatCode} | {egonCode})</i></small><br />
              <strong>{street}, {streetNumber} {buildingNumber}<br />
              {city}</strong>
           </span>
          ) : (
            <span> </span>
          );
        }
      },
      {
        Header: t('FORMS.CommentsLabel'),
        accessor: 'note',
        Cell: ({ value }) => {
          return (
            <small>{value}</small>
          );
        }
      }
    ],
    []
  );

  if(AuthHandler.isAuth()){
    const userRole = AuthHandler.getUserData();
  }

  if (listData == '' || listData === undefined) setData([{}]);

  return (
    <Container fluid>
      <Row className="quoteFeasy">
        {listData.length && (
          <DataTable
            columns={columns}
            data={listData}
            heading={true}
            searchBar={true}
            numofData={numofData}
            tableHeading={t('QUOTELIST.FEASIBILITY.TableHeading')}
            searchMessage={t('OTHERS.Search')}
            filterButton={false}
            downloadButton={false}
            newValueButton={true}
            newValueText={t('QUOTELIST.AddRequest')}
            newValueLink="/requests/newfeasibility"
            inputHandler={inputHandler}
            fetchData={fetchData}
            pageCount={pageCount}
            sortHandler={sortHandler}
            sortValue={sortVal}
            inputText={inputText}
            paginationItemsStartingPoint={ConfigHandler.getConfig().datatables.defaultStartingPoint}
            paginationItemsCount={ConfigHandler.getConfig().datatables.defaultPaginationCount}
          />
        )}
      </Row>
    </Container>
  );
}

export default FeasyList;
